import { Pattern, PatternDB, PatternSource, PatternSourceSet, PatternSourceType } from "@pattern.report/loot-tables/dist/lib/datatypes"
import axios from "axios";
import { DestinyProfileUserInfoCard } from "bungie-api-ts/destiny2";
import { UserInfoCard } from "bungie-api-ts/user";

export type SortedPatternSet = {
    metadata: PatternSource;
    patterns: Pattern[]
}
export type SortedPatternDB = SortedPatternSet[]

export const sortPatterns = (patterns: Pattern[]) => {
    return patterns.sort(
        (a : Pattern, b : Pattern) => {
            if(a.data.rarity === b.data.rarity){
                return a.name.localeCompare(b.name)
            }else{
                return a.data.rarity - b.data.rarity
            }
        }
    )
}

export const sortSources = (sources: SortedPatternDB) => {
    return sources.sort((a: SortedPatternSet, b: SortedPatternSet) => {
        if(a.metadata.season === b.metadata.season){
            return a.metadata.type - b.metadata.type
        }else{
            return b.metadata.season - a.metadata.season
        }
    })
}

export const groupPatternDBBySource = (db : PatternDB, groupExotics = true) => {
    const grouped : Map<PatternSourceSet | string, SortedPatternSet> = new Map();
    db.forEach(pattern => {
        let set = pattern.source.set;
        let source = pattern.source;
        if(source.type === PatternSourceType.EXOTICMISSION && groupExotics){
            set = PatternSourceSet.EXOTICMISSIONGENERIC;
            source = {
                season: -1,
                set: PatternSourceSet.EXOTICMISSIONGENERIC,
                type: PatternSourceType.EXOTICMISSION
            }
        }
        const bySet = grouped.get(set);
        if(bySet){
            grouped.set(set, { metadata : source, patterns: [pattern, ...bySet.patterns]})
        }else{
            grouped.set(set, { metadata : source, patterns: [pattern]})
        }
    })
    return [...grouped.values()]
}

export const sortPatternDBBySource = (db: PatternDB) => {
    const grouped : SortedPatternSet[] = groupPatternDBBySource(db).map(
        sp => {
            return {
                metadata: sp.metadata, patterns: sortPatterns(sp.patterns)
            } as SortedPatternSet
        }
    );
    return sortSources(grouped);
}

export const formatImageUrl = (url : string) => {
    return `${axios.defaults.baseURL}${url}`
}

const getActivityImageSpecific = (source: PatternSource) => {
    switch(source.set){
        case PatternSourceSet.DARES:
            return 'activities/dares.jpg'
        case PatternSourceSet.WELLSPRING:
            return 'activities/wellspring.jpg'
        case PatternSourceSet.NEOMUNA:
            return 'destinations/neomuna.jpg'
        case PatternSourceSet.RELIC:
            return 'destinations/relic.jpg'
        case PatternSourceSet.THRONEWORLD:
            return 'destinations/throneworld.jpg'
        case PatternSourceSet.DUALITY:
            return 'dungeons/duality.jpg'
        case PatternSourceSet.CROTA:
            return 'raids/crota.jpg'
        case PatternSourceSet.DSC:
            return 'raids/deepstonecrypt.jpg'
        case PatternSourceSet.KF:
            return 'raids/kingsfall.jpg'
        case PatternSourceSet.LASTWISH:
            return 'raids/lastwish.jpg'
        case PatternSourceSet.ROOT:
            return 'raids/root.jpg'
        case PatternSourceSet.VOW:
            return 'raids/vow.jpg'
        case PatternSourceSet.UNDYING:
            return 'reprise/garden.jpg'
        case PatternSourceSet.IKELOS:
            return 'reprise/hellas.jpg'
        case PatternSourceSet.LEVIATHAN_1:
            return 'reprise/leviathan_1.jpg'
        case PatternSourceSet.LEVIATHAN_2:
            return 'reprise/leviathan_2.jpg'
        case PatternSourceSet.DEEP:
            return 'seasons/deep.jpg'
        case PatternSourceSet.DEFIANCE:
            return 'seasons/defiance.jpg'
        case PatternSourceSet.HAUNTED:
            return 'seasons/haunted.jpg'
        case PatternSourceSet.PLUNDER:
            return 'seasons/plunder.jpg'
        case PatternSourceSet.RISEN:
            return 'seasons/risen.jpg'
        case PatternSourceSet.SERAPH:
            return 'seasons/seraph.jpg'
        case PatternSourceSet.WISH:
            return 'seasons/wish.jpg'
        case PatternSourceSet.WITCH:
            return 'seasons/witch.jpg'
        case PatternSourceSet.ECHOES:
            return 'seasons/echoes.jpg'
        case PatternSourceSet.PALEHEART:
            return 'destinations/paleheart.jpg'
        case PatternSourceSet.SE:
            return 'raids/se.png'
        case PatternSourceSet.GOS:
            return 'raids/gos.png'
        case PatternSourceSet.VOG:
            return 'raids/vog.jpg'
    }
    if(source.type === PatternSourceType.EXOTICMISSION){
        return 'exoticmission/voxobscura.jpg'
    }
    return '';
}

export const getActivityImage = (source: PatternSource) => {
    return '../assets/' + getActivityImageSpecific(source);
}

export const getActivityImageResized = (source: PatternSource, width: number) => {
    return `../cdn-cgi/image/width=${width},quality=75/assets/` + getActivityImageSpecific(source);
}

export const formatBungieNameFromUserInfoCard = (card : UserInfoCard | DestinyProfileUserInfoCard) => {
    const code = card.bungieGlobalDisplayNameCode;
    const codeFmt = '0000' + code;
    return `${card.bungieGlobalDisplayName}#${codeFmt.slice(-4)}`
}

export const formatLinkFromUserInfoCard = (card : UserInfoCard) => {
    return `/${card.membershipType}/${card.membershipId}`;
}

export const getMembershipIcon = (membershipType : number) => {
    return '../assets/platformicons/' + getMembershipIconSpecific(membershipType)
}

export const getMembershipIconSpecific = (membershipType : number) => {
    switch(membershipType){
        case 1:
            return 'xbox.png'
        case 2: 
            return 'ps.png'
        case 3:
            return 'steam.png'
        case 4:
            return 'blizzard.png'
        case 5:
            return 'stadia.png'
        case 6:
            return 'egs.png'
    }
    return '';
}