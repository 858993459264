import './Home.css';

import { Box, Typography } from '@mui/material';
import ReportSearchBar from './ReportSearchBar';

const Home = () => {
    document.title = 'pattern.report';
    return (
        <Box
            className='home-root'
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
                flexDirection: { md: 'row', sm: 'column', xs: 'column' },
            }}
        >
            <Box
                className='home-content-left'
                sx={{
                    width: {
                        md: '50vw',
                        sm: '75vw',
                        xs: '75vw',
                        display: 'flex',
                        flexGrow: 2,
                        flexBasis: { md: '50%', sm: '90%', xs: '90%' },
                        alignItems: 'center'
                    },
                }}
            >
                <Box
                    sx={{
                        display: {
                            md: 'flex',
                            sm: 'none',
                            xs: 'none',
                            flexBasis: '10%',
                        },
                    }}
                />
                <Box className='home-content-inner' sx={{ flexGrow: 2 }}>
                    <Box className='home-content-title'>
                        <Box
                            className='home-content-logo'
                            component='img'
                            src='./assets/pattern_logo.png'
                        />
                        <Typography
                            className='home-content-title-text'
                            variant='h2'
                            sx={{ fontSize: { md: '2.5rem', xs: '1.5rem' } }}
                        >
                            pattern.report
                        </Typography>
                    </Box>
                    <Box className='home-content-divider' />
                    <Box className='home-searchbar-container'>
                        <ReportSearchBar />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: {
                            md: 'flex',
                            sm: 'none',
                            xs: 'none',
                            flexBasis: '10%',
                        },
                    }}
                />
            </Box>
            <Box
                className='home-content-right'
                sx={{
                    width: { md: '50vw', sm: '100vw', xs: '100vw' },
                    height: { md: '100vh', sm: '25%', xs: '25%' },
                    flexGrow: 1,
                    overflow: 'hidden',
                    display: 'flex'
                    // display: { md: 'flex', sm: 'none', xs: 'none' },
                }}
            >
                <Box className='home-background-image-container' sx={{display: 'flex', marginBottom: 0}}>
                    <Box
                        className='home-background-image'
                        component='img'
                        src='./assets/home.jpg'
                        sx={{
                            alignSelf: 'end',
                            height:{md: '100vh', sm: 'auto', xs: 'auto'},
                            width: {xl: 'auto', lg: 'auto', md: 'auto', sm: '100vw', xs: '100vw'},
                            marginBottom: 0
                        }}
                    />
                </Box>
            </Box>

            {/* <Box sx={{ display: { md: 'flex', sm: 'none', xs: 'none' } }}>
                <Box className="home-background-image-container">
                    <Box className="home-background-image" component="img" src="./assets/home.jpg" />
                </Box>
            </Box> */}
        </Box>
    );
};

export default Home;
