import { Box } from "@mui/material";
import { SortedPatternSet, getActivityImage, getActivityImageResized } from "./DataHelpers";

export interface WeaponSetImageProps {
    set : SortedPatternSet;
}

const WeaponSetImage = ({ set }: WeaponSetImageProps) => {
    const imgurl = process.env.NODE_ENV === 'production' ? getActivityImageResized(set.metadata, 1200) : getActivityImage(set.metadata)
    return (
        <Box className="weaponset-image-container">
            <Box className="weaponset-image" component="img" sx={{
                content: {
                    xs: `url(${imgurl})`,
                    xl: `url(${imgurl})`
                }
            }}></Box>
        </Box>
    )
}

export default WeaponSetImage